import get from 'lodash/get'

export default function ventilationReportEndpointConverter({ data }) {
  const thresholds = get(data, ['attributes', 'thresholds'])
  const dataSet = get(data, ['attributes', 'data-sets'], []).find(
    (d) => (d.name = 'absolute humidity production')
  )
  const points = get(dataSet, ['points'])
  const unit = get(dataSet, ['unit'])
  return { thresholds, points, unit }
}
