import React, { Component, Suspense } from 'react'
import { Router } from '@reach/router'
import { datadogLogs } from '@datadog/browser-logs'

import Authentication from './Authentication'
import Header from './Header'
import Footer from './Footer'
import Spinner from './Spinner'
import AccountList from './AccountList'
import TenancyList from './TenancyList'
import TrackPageViews from './TrackPageViews'
import NotFound from './NotFound'

import { fetchLoggedInUser } from './helpers/api'

import {
  setActiveApiKey,
  setAccountList,
  setShowLoginModal,
} from './state/accounts'
import { setUser } from './state/user'

// Lazy loaded components
const AccountActivation = React.lazy(() =>
  import(/* webpackChunkName: "AccountActivation" */ './AccountActivation')
)
const AccountEnforcer = React.lazy(() =>
  import(/* webpackChunkName: "AccountEnforcer" */ './AccountEnforcer')
)
const Household = React.lazy(() =>
  import(/* webpackChunkName: "Household" */ './Household')
)
const ResetPassword = React.lazy(() =>
  import(/* webpackChunkName: "ResetPassword" */ './ResetPassword')
)

/* Main component ----------------------------------------------------------- */
class App extends Component {
  async componentDidMount() {
    try {
      // Hydrate the global accounts state from localStorage
      const activeApiKey = localStorage.getItem('activeApiKey')
      const accountList = JSON.parse(localStorage.getItem('accountList'))
      if (activeApiKey && accountList && accountList.length > 0) {
        setActiveApiKey(activeApiKey)
        setAccountList(accountList)
        setShowLoginModal(false)
        datadogLogs.logger.info(
          'App.componentDidMount: Restored session from localStorage'
        )
      } else {
        datadogLogs.logger.info(
          'App.componentDidMount: Initiated new demo session'
        )
      }
      // Load required data
      const user = await fetchLoggedInUser()
      setUser(user)
    } catch (error) {
      datadogLogs.logger.error(`App.componentDidMount: ${error.message}`)
    }
  }

  componentDidCatch(error = {}, info = {}) {
    datadogLogs.logger.error(`${error.message}, ${info.componentStack}`)
  }

  render() {
    return (
      <div className="min-vh-100">
        <Header />
        <Suspense fallback={<Spinner className="mv4" />}>
          <Router
            component="main"
            role="main"
            className="w-100 mw8 mh3-m center-l ph3 ph4-l pv2 pv3-m pv4-l flex-grow-1"
          >
            <AccountList path="/" />
            <AccountList path="/account" />
            <AccountEnforcer path="/account/:accountId">
              <TenancyList path="/" />
              <TenancyList path="household" />
              <Household path="household/:householdId" />
              <NotFound default />
            </AccountEnforcer>
            <ResetPassword path="/password-reset/:token" />
            <AccountActivation path="/activate/:token" />
            <NotFound default />
          </Router>
        </Suspense>
        <Authentication />
        <Footer />
        <TrackPageViews />
      </div>
    )
  }
}

export default App
