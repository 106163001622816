import { wrapResponse } from './utils'
import {
  getName,
  getEmailFromName,
  getPhoneNumber,
  getRandom,
} from '../../fake'

export function generateTenant({ id, name, phone }) {
  return {
    id: id,
    type: 'accounts',
    attributes: {
      name,
      email: getEmailFromName(name),
      phone,
      role: 'tenant',
    },
    relationships: {
      'email-settings': {
        data: {
          // Reuse tenant id for email settings, fine for testing purposes
          id: id,
          type: 'account-email-settings',
        },
      },
      tenancy: {
        data: {
          // Reuse tenant id for tenancy, fine for testing purposes
          id: id,
          type: 'tenancies',
        },
      },
    },
  }
}

export function generateEmailSettings({ id }) {
  return {
    id: id,
    type: 'account-email-settings',
    attributes: {
      'ventilation-alarm': getRandom([true, false]),
    },
  }
}

export default function generateTenants({ tenantIdsArray = [] }) {
  return wrapResponse({
    data: tenantIdsArray.map((id) =>
      generateTenant({
        id,
        name: getName(),
        phone: getPhoneNumber(),
      })
    ),
    included: tenantIdsArray.map((id) =>
      // Reuse tenant id for email settings, fine for testing purposes
      generateEmailSettings({ id })
    ),
  })
}
