/**
 * httpClient.js contains two axios instances:
 *
 * 1. The real axios instance which will actually send requests to the Legolas
 *    backend,
 *
 * 2. A demo mode axios instance, which has axios-mock-adapter set up to catch
 *    requests and return predefined data
 *
 * This module exports apiProxy() which will determine if we are in demo-mode or
 * not and return the appropriate axios instance.
 */

import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'
import * as R from 'ramda'
import build from 'redux-object'
import random from 'lodash/random'
import get from 'lodash/get'
import normalize from 'json-api-normalizer'

import accounts from '../../state/accounts'
import {
  generateHousehold,
  generateAddress,
  generateTenancy,
} from './generators/household'
import {
  getApartmentIdentifier,
  getCity,
  getName,
  getPhoneNumber,
  getStreet,
} from '../fake'
import { generateTenant } from './generators/tenants'
import generatePing from './generators/ping'
import generateDevices from './generators/devices'
import generateVentilationReport from './generators/ventilationReport'
import generateUsageReport from './generators/usageReport'
import { wrapResponse } from './generators/utils'

const axiosConfig = {
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 18000,
  headers: {
    'Content-Type': 'application/vnd.api+json',
    Accept: 'application/vnd.api+json',
  },
}

/* 1. Axios instance for making requests to the real API -------------------- */
export const realApi = axios.create(axiosConfig)
export const realApiAsAngel = axios.create(axiosConfig)

// Intercept requests to authenticate with API key, if available
realApi.interceptors.request.use((config) => {
  const accountsState = accounts.get()
  const activeApiKey = accountsState.activeApiKey
  const activeSudoAccount = accountsState.activeSudoAccount || null

  if (activeApiKey) {
    config.auth = { username: activeSudoAccount, password: activeApiKey }
  }

  return config
})

realApiAsAngel.interceptors.request.use((config) => {
  const accountsState = accounts.get()
  const angelApiKey = R.pipe(
    R.propOr([], 'accountList'),
    R.find(R.propEq('role', 'angel')),
    R.prop('apiKey')
  )(accountsState)

  if (angelApiKey) {
    config.auth = { username: null, password: angelApiKey }
  }

  return config
})

/* 2. Axios instance for mocking requests in demo mode ---------------------- */
const mockApi = axios.create(axiosConfig)

// Set up mocking for demo mode
const demo = new MockAdapter(mockApi)

// Cache some data across different demo requests
let cachedData = {
  data: generateHousehold({
    id: '101',
    number: '101',
    apartment: getApartmentIdentifier(random(1, 10)),
    addressId: '101',
  }),
  included: [
    generateAddress({
      id: '101',
      streetAndNumber: `${getStreet()} ${random(7, 150)}`,
      city: getCity(),
      zip: (random(11, 90) * 100).toString(),
      propertyId: '100',
      householdIdsArray: ['101'],
    }),
    generateTenancy({ id: '101' }),
    generateTenant({ id: '101', name: getName(), phone: getPhoneNumber() }),
  ],
}
let normalizedCache = normalize(cachedData, {
  camelizeKeys: false,
  camelizeTypeValues: false,
})

demo
  // fetchLoggedInUser() ---------------------------------------------------- //
  .onGet(/^\/v6\/ping/)
  .reply(() => [200, generatePing()])

  // loadDevicesByHousehold ------------------------------------------------- //
  .onGet(
    /^\/v6\/devices\?filter\[household-id\]=\d+&include=room,device-type,last-measurements/
  )
  .reply(() => [200, generateDevices()])

  // loadTenancies() -------------------------------------------------------- //
  .onGet('/v6/tenancies')
  .reply(() => {
    return [
      200,
      wrapResponse({
        data: R.pipe(R.propOr({}, 'tenancies'), R.values)(normalizedCache),
      }),
    ]
  })

  // loadHousehold() -------------------------------------------------------- //
  .onGet(/^\/v6\/households\/\d+\?include=address/)
  .reply((config) => {
    const householdId = /\/households\/(\d+)/.exec(config.url)[1].toString()
    const household = build(normalizedCache, 'households', householdId)
    return [
      200,
      wrapResponse({
        data: get(normalizedCache, ['households', householdId]),
        included: [
          // address
          get(normalizedCache, [
            'addresses',
            get(household, ['address', 'id']),
          ]),
        ].filter(Boolean),
      }),
    ]
  })

  // fetchVentilationReport() ----------------------------------------------- //
  .onPost('/v6/reports/ventilation')
  .reply((config) => {
    const { data } = JSON.parse(config.data)
    return [
      200,
      generateVentilationReport({
        fromDate: data.attributes['from-date'],
        toDate: data.attributes['to-date'],
      }),
    ]
  })

  // fetchUsageReport() ----------------------------------------------------- //
  .onPost(
    /^\/v6\/reports\/(water-usage|heat-usage|heat-cost|electricity-usage)$/
  )
  .reply((config) => {
    const { data } = JSON.parse(config.data)
    return [
      200,
      generateUsageReport({
        fromDate: new Date(data.attributes['from-date']),
        toDate: new Date(data.attributes['to-date']),
        type: data.type,
        waterType: data.attributes['water-type'],
      }),
    ]
  })

  .onAny()
  .passThrough()

/* Proxy to determine which axios instance to use --------------------------- */
export function apiProxy() {
  // If an API key is available in local state, use the real API, otherwise fall
  // back to demo mode where requests are mocked
  if (accounts.get().activeApiKey) {
    return realApi
  } else {
    return mockApi
  }
}
