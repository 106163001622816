const units = {
  celsius: ' °C',
  percent: ' %',
  m3: ' m³',
  wh: ' Wh',
  kwh: ' kWh',
  mwh: ' MWh',
  units: ' enheder',
  'gram per cubic meter': ' g/m³',
}

export function formatUnit(unit) {
  return units[unit] || ' ' + unit
}
