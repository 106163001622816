import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import s from './style.module.css'

const Button = ({
  onClick,
  className,
  variant = 'button',
  size = 'medium',
  loading = false,
  disabled = false,
  children,
  title,
  slim = false,
  ...rest
}) => {
  return (
    <button
      onClick={onClick}
      data-variant={variant}
      style={{ transition: 'opacity 0.2s ease-in-out' }}
      className={cx(
        s.button,
        'bg-animate',
        size === 'small'
          ? cx('f7 pv1 br2', !slim && 'ph2')
          : cx('f6 pv2 br3', !slim && 'ph3'),
        disabled ? 'o-60' : 'pointer',
        {
          'white bg-orange shadow-1': variant === 'button',
          'orange bn bg-transparent': variant === 'link',
        },
        className
      )}
      disabled={disabled}
      title={title || (disabled ? 'Formularen er ikke udfyldt korrekt' : null)}
      {...rest}
    >
      <span className="db pv1">
        {children}
        {loading && <span>...</span>}
      </span>
    </button>
  )
}

Button.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node,
  variant: PropTypes.oneOf(['button', 'link']),
  size: PropTypes.oneOf(['small', 'medium']),
  loading: PropTypes.bool,
}

export default Button
