import React, { useEffect } from 'react'
import GoogleAnalytics from 'react-ga'
import { Location } from '@reach/router'

function TrackPageViews({ page }) {
  useEffect(() => {
    GoogleAnalytics.pageview(page)
  }, [page])
  return null
}

export default function SubscribedTrackPageViews(props) {
  return (
    <Location>
      {({ location }) => (
        <TrackPageViews page={location.pathname + location.search} {...props} />
      )}
    </Location>
  )
}
