import React from 'react'
import { Location } from '@reach/router'
import { Subscribe } from 'bey-fix'
import cx from 'classnames'

import DemoBanner from '../DemoBanner'

import accounts from '../state/accounts'
import { getVersionString } from '../helpers/version'

export function Footer({ showDemoBanner = false }) {
  return (
    <footer
      className={cx(
        'w-100 mw9 center ph3 ph4-ns f7 pt3 lh-copy',
        showDemoBanner ? 'pb5' : 'pb3'
      )}
      role="contentinfo"
    >
      <div className="flex flex-column flex-row-ns items-end justify-end">
        {[
          {
            href: 'https://wisehome.dk',
            title: 'Wise Home',
          },
          {
            href: 'mailto:info@wisehome.dk',
            title: 'info@wisehome.dk',
          },
          {
            href: 'tel:+4520693501',
            title: '+45 20 69 35 01',
          },
          {
            href: 'https://wisehome.dk/privatlivspolitik',
            title: 'Privatlivspolitik',
            noprint: true,
          },
        ].map(({ href, title, noprint = false }) => (
          <a
            key={href}
            href={href}
            target={/^(mailto:|tel:)/.test(href) ? null : '_blank'}
            rel="noopener noreferrer"
            className={cx(
              'link orange ml2 ml3-l nowrap',
              noprint && 'no-print'
            )}
          >
            {title}
          </a>
        ))}
        <span className="silver ml2 ml3-l nowrap">{getVersionString()}</span>
        <DemoBanner isVisible={showDemoBanner} />
      </div>
    </footer>
  )
}

export default function SubscribedFooter(props) {
  return (
    <Location>
      {({ location }) => (
        <Subscribe to={accounts}>
          {(accounts) => {
            const isPasswordResetPage = /^\/password-reset\//.test(
              location.pathname
            )
            const isAccountActivationPage = /^\/activate/.test(
              location.pathname
            )
            return (
              <Footer
                {...props}
                showDemoBanner={
                  !accounts.activeApiKey &&
                  !accounts.showLoginModal &&
                  !isPasswordResetPage &&
                  !isAccountActivationPage
                }
              />
            )
          }}
        </Subscribe>
      )}
    </Location>
  )
}
