import random from 'lodash/random'
import times from 'lodash/times'
import constant from 'lodash/constant'
import reduce from 'lodash/reduce'

import faked from './faked.json'

export const getRandom = (array) => array[random(0, array.length - 1)]

export const getFirstName = () => getRandom(faked.firstName)

export const getLastName = () => getRandom(faked.lastName)

export const getName = () => `${getFirstName()} ${getLastName()}`

export const getEmailFromName = (name = '') =>
  `${name.toLowerCase().replace(' ', '-')}@email.dk`

export const getPhoneNumber = () => times(4, () => random(10, 99)).join(' ')

export const getStreet = () =>
  `${getRandom(faked.streetPrefix)}${getRandom(faked.streetSuffix)}`

export const getCity = () =>
  `${getRandom(faked.cityPrefix)}${getRandom(faked.citySuffix)}`

export const getApartmentIdentifier = (n) =>
  `${n < 3 ? 'st' : `${(n - 2 + (n % 2)) / 2}.`} ${n % 2 === 0 ? 'th' : 'tv'}`

// E.g. { ok: 5, warn: 1, alert: 1 } => 'ok'
export const getWithProbability = (obj) => {
  const arr = reduce(
    obj,
    (acc, count, status) => {
      return acc.concat(times(count, constant(status)))
    },
    []
  )
  return arr[random(0, arr.length - 1)]
}
