import React, { useState } from 'react'
import * as R from 'ramda'
import { Subscribe } from 'bey-fix'
import { Location } from '@reach/router'
import { Formik } from 'formik'
import { datadogLogs } from '@datadog/browser-logs'

import Alert from '../Alert'
import Button from '../Button'
import FormLabel from '../FormLabel'
import Input from '../Input'
import Modal from '../Modal'

import { setUser } from '../state/user'
import accounts, { setAccountList, setShowLoginModal } from '../state/accounts'

import { fetchAccountUsers, requestPasswordReset } from '../helpers/api'

import personIcon from '../graphics/person.svg'

function Authentication({ location = {}, navigate, isVisible, cachedEmail }) {
  const [submitError, setSubmitError] = useState()
  const [showPasswordEmailSent, setShowPasswordEmailSent] = useState(false)
  // URL params are not available outside the router component, so regex it out
  const accountId = R.pipe(
    R.match(/^\/account\/([^/]+)/),
    R.nth(1)
  )(location.pathname || '')

  function dismissModal() {
    setShowLoginModal(false)
    setSubmitError(null)
    setShowPasswordEmailSent(false)
  }

  async function handleSubmit(values, actions) {
    setSubmitError(null)

    try {
      const { user, accountList } = await fetchAccountUsers({
        email: values.email,
        password: values.password,
      })
      actions.setSubmitting(false)
      setUser(user)
      setAccountList(accountList)
      if (accountId === 'demo' || !accountId) {
        // Let AccountList find the right account to redirect to
        navigate('/account')
      } else {
        // Let AccountEnforcer set or fetch the account
        navigate(`/account/${accountId}`)
      }
      dismissModal()
      datadogLogs.logger.info('Authentication.handleSubmit: Logged in')
    } catch (error) {
      actions.setSubmitting(false)
      if (error.message.toLowerCase().includes('not authorized')) {
        setSubmitError('E-mail eller kodeord er forkert')
      } else {
        setSubmitError(error.message)
      }
      datadogLogs.logger.error(`Authentication.handleSubmit: ${error.message}`)
    }
  }

  async function handleForgotPassword({ email, validateForm }) {
    const errors = await validateForm()
    setSubmitError(null)
    setShowPasswordEmailSent(false)
    if (email && !errors.email) {
      try {
        await requestPasswordReset({ email })
        setSubmitError(null)
        setShowPasswordEmailSent(true)
      } catch (error) {
        if (
          error.message
            .toLowerCase()
            .includes('validation error – email not found')
        ) {
          setSubmitError(
            <div>
              <p className="b mt3 mb3">
                E-mailen blev ikke fundet, dette kan have flere årsager:
              </p>

              <ul className="list mt2 mb0 pl0">
                <li>
                  Din konto er ikke aktiveret (aktiveringslink findes i din
                  mail)
                </li>
                <li className="">Du har ikke fået adgang</li>
                <li className="">Forkert indtastning</li>
              </ul>
            </div>
          )
        } else {
          setSubmitError(error.message)
        }

        setShowPasswordEmailSent(false)
        datadogLogs.logger.error(
          `Authentication.handleForgotPassword: ${error.message}`
        )
      }
    }
  }

  function handleValidation({ email, password }) {
    let errors = {}
    // Required and must be valid
    if (!email || !/^\S+@\S+\.\S+$/.test(email)) {
      errors.email = 'Udfyld en gyldig emailadresse'
    }
    if (!password) {
      errors.password = 'Udfyld venligst dit password'
    } else if (password.length < 8) {
      errors.password = 'Password er mindst 8 tegn'
    }
    return errors
  }

  return (
    <Modal
      onClose={dismissModal}
      isVisible={isVisible}
      title="Log ind"
      icon={personIcon}
    >
      <Formik
        initialValues={{
          email: cachedEmail ?? '',
          password: '',
        }}
        onSubmit={handleSubmit}
        validate={handleValidation}
      >
        {({
          values,
          handleSubmit,
          handleChange,
          handleBlur,
          isSubmitting,
          errors,
          touched,
          validateForm,
        }) => (
          <form onSubmit={handleSubmit}>
            <FormLabel title="E-mail" className="db mt4 mb3">
              <Input
                type="text"
                name="email"
                value={values.email}
                onBlur={handleBlur}
                onChange={handleChange}
                errorMessage={touched.email && errors.email}
              />
            </FormLabel>
            {showPasswordEmailSent ? (
              <Alert
                type="info"
                isDismissable={true}
                onDismiss={() => setShowPasswordEmailSent(false)}
              >
                Vi har sendt dig en email med et link til at nulstille dit
                kodeord.
              </Alert>
            ) : (
              <FormLabel title="Kodeord" className="db mb3">
                <Input
                  type="password"
                  name="password"
                  value={values.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  errorMessage={touched.password && errors.password}
                />
              </FormLabel>
            )}
            {submitError && (
              <Alert
                type="error"
                isDismissable={true}
                onDismiss={() => {
                  setSubmitError(null)
                }}
              >
                {submitError}
              </Alert>
            )}
            <div className="mt3 flex flex-row-reverse items-center">
              <Button
                variant="button"
                type="submit"
                loading={isSubmitting}
                disabled={showPasswordEmailSent}
              >
                Log ind
              </Button>
              <Button
                onClick={dismissModal}
                variant="link"
                className="ml-auto mr3"
                type="button"
              >
                Se en demo
              </Button>
              <Button
                onClick={() =>
                  handleForgotPassword({
                    email: values.email,
                    validateForm,
                  })
                }
                title="Få tilsendt link til at nulstille dit kodeord"
                disabled={showPasswordEmailSent}
                type="button"
                variant="link"
                slim={true}
              >
                Glemt kodeord?
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  )
}

export default function SubscribedAuthentication(props) {
  return (
    <Location>
      {({ location, navigate }) => (
        <Subscribe
          to={accounts}
          on={(state) => ({
            showLoginModal: state.showLoginModal,
            cachedEmail: state.cachedEmail,
          })}
        >
          {({ showLoginModal, cachedEmail }) => (
            <Authentication
              location={location}
              navigate={navigate}
              isVisible={showLoginModal}
              cachedEmail={cachedEmail}
            />
          )}
        </Subscribe>
      )}
    </Location>
  )
}
