export default function accountUsersEndpointConverter({
  data = [],
  included = [],
}) {
  // There is always at most only one included user
  const user = included
    .filter((p) => p.type === 'users')
    .map((user) => ({
      id: user.id,
      name: user.attributes.name,
      email: user.attributes.email,
    }))[0]

  const apiKeysByAccountId = data
    .filter((p) => p.type === 'account-users')
    .reduce((acc, p) => {
      acc[p.relationships.account.data.id] = p.attributes['api-key']
      return acc
    }, {})

  const accountList = included
    .filter(
      (p) =>
        p.type === 'accounts' &&
        (p.attributes.role === 'tenant' || p.attributes.role === 'angel')
    )
    .map((p) => ({
      id: p.id,
      role: p.attributes.role,
      name: p.attributes.name,
      apiKey: apiKeysByAccountId[p.id],
    }))

  const hasAdminAccess =
    included.filter(
      (p) => p.type === 'accounts' && p.attributes.role === 'admin'
    ).length > 0

  if (accountList.length === 0) {
    if (hasAdminAccess) {
      throw new Error(
        'Du har ikke adgang til nogen beboerkonto — log på admin.wisehome.dk for administratoradgang.'
      )
    } else {
      throw new Error('Du har ikke adgang til nogen beboerkonto.')
    }
  }

  return {
    user,
    accountList,
  }
}
