import React, { useState, useEffect } from 'react'
import * as R from 'ramda'
import build from 'redux-object'
import { Link } from '@reach/router'

import Box from '../Box'
import Alert from '../Alert'
import { FallbackText } from '../TextElements'

import { loadTenancies } from '../helpers/api'
import { formatDate } from '../helpers/dates'
import Spinner from '../Spinner'

import keyIcon from '../graphics/key.svg'

function TenancyList({ navigate }) {
  const [loading, setLoading] = useState(true)
  const [resources, setResources] = useState({})
  const [errorMessage, setErrorMessage] = useState()

  useEffect(() => {
    async function _loadTenancies() {
      try {
        await loadTenancies(null, setResources)
      } catch (error) {
        setErrorMessage(error.message)
      } finally {
        setLoading(false)
      }
    }

    _loadTenancies()
  }, [])

  const tenancies = build(resources, 'tenancies') || []

  const sortTenancies = R.sortWith([R.ascend(R.prop('moveInDate'))])

  // If there is only one tenancy, navigate straight to it
  if (tenancies.length === 1) {
    const householdId = R.pipe(R.head, R.path(['household', 'id']))(tenancies)
    navigate(`household/${householdId}`, {
      replace: true,
    })
  }

  return (
    <Box title="Vælg en periode" icon={keyIcon} className="mt4 mw6 center">
      {errorMessage && (
        <Alert type="error" className="mt4 mw6 center">
          {errorMessage}
        </Alert>
      )}
      {loading ? (
        <Spinner className="mv4" />
      ) : tenancies.length > 0 ? (
        <ul className="list pl0 lh-copy tl f6 lh-copy mt4">
          {sortTenancies(tenancies)
            .filter(({ household }) => household && household.id)
            .map(({ id, moveInDate, moveOutDate, household }) => {
              return (
                <li key={id}>
                  <Link
                    to={`household/${household.id}`}
                    className="link orange underline-hover"
                  >
                    {`${formatDate(
                      moveInDate,
                      'DD.MM.YYYY',
                      '?'
                    )} — ${formatDate(moveOutDate, 'DD.MM.YYYY', '?')}`}
                  </Link>
                </li>
              )
            })}
        </ul>
      ) : (
        <div className="mt4 mb3 tc">
          <FallbackText>Ingen periode tilknyttet denne konto</FallbackText>
        </div>
      )}
    </Box>
  )
}

// Re-render the component if account id is changed
export default function KeyedTenancyList(props) {
  return <TenancyList key={props.accountId} {...props} />
}
