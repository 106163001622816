import React, { useEffect } from 'react'
import { navigate } from '@reach/router'
import Button from '../Button'
import { datadogLogs } from '@datadog/browser-logs'

export default function NotFound({ children }) {
  useEffect(() => {
    datadogLogs.logger.warn('NotFound: 404 Not Found')
  }, [])

  return (
    <div className="center tc moon-gray lh-title">
      <p className="f1 b mt3 mb3">
        <span className="ph1 light-gray">?</span>
        <span className="ph1 silver">?</span>
        <span className="ph1 moon-gray">?</span>
        <span className="ph1 silver">?</span>
        <span className="ph1 light-gray">?</span>
      </p>
      <p className="mt0 mb3">{children || 'Siden kan ikke findes.'}</p>
      <p className="mt0 mb3">
        <Button onClick={() => navigate('/')}>Gå til forsiden</Button>
      </p>
    </div>
  )
}
