import React from 'react'
import * as R from 'ramda'
import { Link, Location } from '@reach/router'

import AccountSelector from '../AccountSelector'

import logo from '../graphics/logo.svg'

function Header({ pathname }) {
  const accountPrefix = R.pipe(R.match(/account\/(\d+|demo)/), R.head)(pathname)

  return (
    <header
      className="bg-light-gray bb b--light-silver ph3 ph4-ns pv2 pv3-l"
      role="banner"
    >
      <div className="flex flex-row items-center f6 mw9 center-l pv1">
        <Link to={accountPrefix || '/'} className="mr3 flex-shrink-0">
          <img
            src={logo}
            alt="Wise Home logo"
            width="106"
            height="22"
            className="pb3 pt2"
          />
        </Link>
        <nav className="ml-auto">
          <ul className="mv0 pl0 list flex items-center">
            <li>
              <AccountSelector />
            </li>
          </ul>
        </nav>
      </div>
    </header>
  )
}

export default function SubscribedHeader(props) {
  return (
    <Location>
      {({ location: { pathname } }) => (
        <Header pathname={pathname} {...props} />
      )}
    </Location>
  )
}
