import dayjs from 'dayjs'
import padStart from 'lodash/padStart'
import random from 'lodash/random'
import { getRandom, getCity } from '../../fake'

export function generateTenancy({ id }) {
  return {
    id: id,
    type: 'tenancies',
    attributes: {
      number: 1,
      'move-in-date': `${random(1995, 2018)}-${padStart(
        random(1, 12).toString(),
        2,
        '0'
      )}-01`,
      'move-out-date': getRandom([
        null,
        `${dayjs().add(random(2, 6), 'months').format('YYYY-MM')}-${getRandom([
          '01',
          '15',
        ])}`,
      ]),
    },
    relationships: {
      tenant: {
        data: {
          // Reuse tenancy id for tenant id, fine for mocking purposes
          id,
          type: 'accounts',
        },
      },
      household: {
        data: {
          // Reuse tenancy id for household id, fine for mocking purposes
          id,
          type: 'households',
        },
      },
    },
  }
}

export function generateHousehold({
  id,
  number,
  apartment,
  addressId,
  alarmStatusIdsArray = [],
}) {
  return {
    id: id,
    type: 'households',
    attributes: {
      number,
      'standard-dk-sort': number,
      apartment,
    },
    relationships: {
      'alarm-statuses': {
        data: alarmStatusIdsArray.map((id) => ({
          id: id,
          type: 'alarm-statuses',
        })),
      },
      address: {
        data: {
          id: addressId,
          type: 'addresses',
        },
      },
      tenancies: {
        data: [
          {
            // Reuse household id for tenancy id, fine for mocking purposes
            id,
            type: 'tenancies',
          },
        ],
      },
      tenant: {
        data: {
          // Reuse household id for tenant id, fine for mocking purposes
          id,
          type: 'accounts',
        },
      },
    },
  }
}

export function generateAddress({
  id,
  streetAndNumber,
  city,
  zip,
  propertyId,
  householdIdsArray = [],
}) {
  return {
    id: id,
    type: 'addresses',
    attributes: {
      'street-and-number': streetAndNumber,
      // Add a place to a single address for testing purposes
      place: id === '300' ? `Nedre ${getCity()}` : null,
      city,
      'zip-code': zip,
      'country-code-alpha3': 'DNK',
    },
    relationships: {
      property: {
        data: {
          id: propertyId,
          type: 'properties',
        },
      },
      households: {
        data: householdIdsArray.map((id) => ({
          id,
          type: 'households',
        })),
      },
    },
  }
}
