import dayjs from 'dayjs'
import map from 'lodash/map'
import keys from 'lodash/keys'
import get from 'lodash/get'
import times from 'lodash/times'
import random from 'lodash/random'
import flatten from 'lodash/flatten'
import pick from 'lodash/pick'

import { wrapResponse } from './utils'

// Cribbed from wise-home/legolas/apps/legolas/lib/legolas/devices/type.ex
const DEVICE_TYPES = {
  1: {
    kind: 'humidity',
    manufacturer: 'Develco Products',
    name: 'Humidity sensor',
    'power-source': 'battery',
    protocol: 'zigbee',
    'system-name': 'humidity_sensor',
  },
  2: {
    kind: 'router',
    manufacturer: 'Develco Products',
    name: 'Smart plug',
    'power-source': 'battery',
    protocol: 'zigbee',
    'system-name': 'smart_plug',
  },
  3: {
    kind: 'cold_water',
    manufacturer: 'Kamstrup',
    name: 'Multical 21 cold water',
    'power-source': 'battery',
    protocol: 'wmbus',
    'system-name': 'kamstrup_multical_21_cold',
  },
  4: {
    kind: 'heat',
    manufacturer: 'Kamstrup',
    name: 'Multical 302 heat (outlet)',
    'power-source': 'battery',
    protocol: 'wmbus',
    'system-name': 'kamstrup_multical_302',
  },
  5: {
    kind: 'heat_cost',
    manufacturer: 'Bmeters',
    name: 'Hydroclima OMS heat cost',
    'power-source': 'battery',
    protocol: 'wmbus',
    'system-name': 'bmeters_hydroclima_oms',
  },
  6: {
    kind: 'hot_water',
    manufacturer: 'Kamstrup',
    name: 'Multical 21 hot water',
    'power-source': 'battery',
    protocol: 'wmbus',
    'system-name': 'kamstrup_multical_21_hot',
  },
  7: {
    kind: 'humidity',
    manufacturer: 'Lansen',
    name: 'Humidity sensor',
    'power-source': 'battery',
    protocol: 'wmbus',
    'system-name': 'lansen_humidity_sensor',
  },
  8: {
    kind: 'leakage',
    manufacturer: 'Lansen',
    name: 'Leakage sensor',
    'power-source': 'battery',
    protocol: 'wmbus',
    'system-name': 'lansen_leakage_sensor',
  },
  9: {
    kind: 'heat',
    manufacturer: 'Kamstrup',
    name: 'Multical 302 heat (inlet)',
    'power-source': 'battery',
    protocol: 'wmbus',
    'system-name': 'kamstrup_multical_302_inlet',
  },
  10: {
    kind: 'heat_cost',
    manufacturer: 'Danfoss A/S',
    name: 'SonoHCA wM-Bus',
    'power-source': 'battery',
    protocol: 'wmbus',
    'system-name': 'danfoss_sono_hca',
  },
  11: {
    kind: 'electricity',
    manufacturer: 'Carlo Gavazzi',
    name: 'EM24 Electricity',
    'power-source': 'mains',
    protocol: 'wmbus',
    'system-name': 'gavazzi_em24',
  },
}

const ROOM_NAMES = {
  1: 'Stue',
  2: 'Soveværelse',
  3: 'Bad',
  4: 'Køkken',
  5: 'Vær 1',
  6: 'Vær 2',
  7: 'Vær 3',
}

export function generateRoom({ id }) {
  return {
    id,
    type: 'rooms',
    attributes: {
      name: ROOM_NAMES[id],
    },
  }
}

export function generateMeasurement({
  id,
  minValue,
  maxValue,
  meterType,
  unit,
}) {
  const randomValue = random(minValue, maxValue)
  function getDecimalDigitCount(unit = '') {
    switch (unit.trim()) {
      case 'units':
        return 1
      case 'mwh':
      case 'MWh':
        return 3
      case 'm³':
      case 'm3':
        return 2
      default:
        return 0
    }
  }
  return {
    attributes: {
      'measurement-at': dayjs().subtract(random(1, 30), 'minute').toISOString(),
      'meter-type': meterType,
      value: `${randomValue.toFixed(getDecimalDigitCount(unit))} ${unit}`,
    },
    id,
    type: 'measurements',
  }
}

export const getMeasurements = (deviceType, deviceId) => {
  // Current month, 1-indexed
  const currentMonthIndex = dayjs().month() + 1
  switch (deviceType) {
    case 'humidity':
      return [
        generateMeasurement({
          id: `${deviceId}-1`,
          meterType: 'humidity',
          minValue: 40,
          maxValue: 70,
          unit: '%',
        }),
        generateMeasurement({
          id: `${deviceId}-2`,
          meterType: 'temperature',
          minValue: 19,
          maxValue: 23,
          unit: '°C',
        }),
      ]
    case 'heat_cost':
      return [
        generateMeasurement({
          id: `${deviceId}-1`,
          meterType: 'heat_cost',
          minValue: currentMonthIndex * 9.5,
          maxValue: currentMonthIndex * 12.5,
          unit: 'units',
        }),
      ]
    case 'heat':
      return [
        generateMeasurement({
          id: `${deviceId}-1`,
          meterType: 'heat_energy',
          minValue: currentMonthIndex * 0.4,
          maxValue: currentMonthIndex * 0.6,
          unit: 'MWh',
        }),
      ]
    case 'cold_water':
      return [
        generateMeasurement({
          id: `${deviceId}-1`,
          meterType: 'cold_water',
          minValue: currentMonthIndex * 3.0,
          maxValue: currentMonthIndex * 4.5,
          unit: 'm³',
        }),
      ]
    case 'hot_water':
      return [
        generateMeasurement({
          id: `${deviceId}-1`,
          meterType: 'hot_water',
          minValue: currentMonthIndex * 3.0,
          maxValue: currentMonthIndex * 4.5,
          unit: 'm³',
        }),
      ]
    case 'electricity':
      return [
        generateMeasurement({
          id: `${deviceId}-1`,
          meterType: 'electricity_energy',
          minValue: currentMonthIndex * 0.25,
          maxValue: currentMonthIndex * 0.32,
          unit: 'MWh',
        }),
      ]
    default:
      return []
  }
}

export function generateDevice({ id, deviceTypeId, deviceTypeKind }) {
  const protocol = get(DEVICE_TYPES, [deviceTypeId, 'protocol'])
  // E.g. 079134572D2C1B06
  // > 16 to get 2-character hexadecimal pairs
  // <= 90 to favor 'numeric' values, which is the case for most real devices
  const serial = times(8, () => random(16, 90).toString(16))
    .join('')
    .toUpperCase()
  const hexPairMatch = /[0-9a-fA-F]{2}/g
  const meterNumber =
    protocol === 'wmbus'
      ? serial.substring(0, 8).match(hexPairMatch).reverse().join('')
      : serial

  return {
    id: `${deviceTypeKind}-${id}`,
    type: 'devices',
    attributes: {
      online: 'yes',
      protocol,
      serial,
      number: meterNumber,
    },
    relationships: {
      'device-type': {
        data: {
          id: `${deviceTypeKind}-${deviceTypeId}`,
          type: 'device-types',
        },
      },
      'last-measurements': {
        // Link to two measurements for humidity devices (humidity and
        // temperature), one for other devices
        data: (deviceTypeKind === 'humidity' ? [1, 2] : [1]).map((num) => ({
          id: `${deviceTypeKind}-${id}-${num}`,
          type: 'measurements',
        })),
      },
      room: {
        data: {
          id,
          type: 'rooms',
        },
      },
    },
  }
}

export default function generateDevices() {
  const devices = []
    .concat(
      // Five heat_cost devices
      keys(pick(ROOM_NAMES, ['1', '2', '4', '5', '6'])).map((id) =>
        generateDevice({ id, deviceTypeId: '5', deviceTypeKind: 'heat_cost' })
      )
    )
    .concat(
      // Five humidity devices
      keys(pick(ROOM_NAMES, ['1', '2', '3', '5', '6'])).map((id) =>
        generateDevice({ id, deviceTypeId: '1', deviceTypeKind: 'humidity' })
      )
    )
    .concat(
      // Two hot_water devices
      keys(pick(ROOM_NAMES, ['3', '4'])).map((id) =>
        generateDevice({ id, deviceTypeId: '6', deviceTypeKind: 'hot_water' })
      )
    )
    .concat(
      // Two cold_water devices
      keys(pick(ROOM_NAMES, ['3', '4'])).map((id) =>
        generateDevice({
          id,
          deviceTypeId: '3',
          deviceTypeKind: 'cold_water',
        })
      )
    )
    .concat(
      // One heat device
      keys(pick(ROOM_NAMES, ['3'])).map((id) =>
        generateDevice({
          id,
          deviceTypeId: '4',
          deviceTypeKind: 'heat',
        })
      )
    )
    .concat(
      // One electricity device
      keys(pick(ROOM_NAMES, ['1'])).map((id) =>
        generateDevice({
          id,
          deviceTypeId: '11',
          deviceTypeKind: 'electricity',
        })
      )
    )

  return wrapResponse({
    data: devices,
    included: keys(ROOM_NAMES)
      .map((id) => generateRoom({ id }))
      .concat(
        map(
          pick(DEVICE_TYPES, ['1', '3', '4', '5', '6', '11']),
          (val, key) => ({
            // Include 'kind' in the id in order to mock correct measurements when
            // fetching latest report, which only receives device id
            id: `${val.kind}-${key}`,
            type: 'device-types',
            attributes: val,
          })
        )
      )
      .concat(
        // Last measurements
        flatten(
          devices.map((device) => {
            return getMeasurements(device.id.split('-')[0], device.id)
          })
        )
      ),
  })
}
