import random from 'lodash/random'

import { formatDateAsISOWithoutOffset, eachDay } from '../../dates'
import { wrapResponse } from './utils'

export default function generateVentilationReport({ fromDate, toDate }) {
  return wrapResponse({
    data: {
      id: '',
      type: 'ventilation-reports',
      attributes: {
        'from-time': formatDateAsISOWithoutOffset(new Date(fromDate)),
        'to-time': formatDateAsISOWithoutOffset(new Date(toDate)),
        thresholds: [
          {
            from: 0,
            name: 'ok',
            to: 4,
          },
          {
            from: 4,
            name: 'warning',
            to: 6,
          },
          {
            from: 6,
            name: 'alert',
            to: null,
          },
        ],
        'data-sets': [
          {
            name: 'absolute humidity production',
            unit: 'gram per cubic meter',
            points: eachDay(fromDate, toDate).map((d) => ({
              time: formatDateAsISOWithoutOffset(d),
              value: Math.abs(
                1 / (d.getDay() + 1) + 1 / d.getDate() + random(3.1, 3.9)
              ),
            })),
          },
        ],
      },
    },
  })
}
