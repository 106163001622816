import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import noop from 'lodash/noop'

import { ReactComponent as CloseIcon } from '../graphics/close.svg'
import s from './style.module.css'

class Input extends Component {
  render() {
    const {
      value,
      onChange = noop,
      clearable = false,
      type = 'text',
      readOnly = false,
      prefixIcon,
      errorMessage,
      className,
      ...rest
    } = this.props
    return (
      <div className="relative">
        <input
          className={cx(
            'w-100 border-box bg-animate ph3 navy ba b--silver br3 shadow-1 f6 moon-gray',
            className,
            errorMessage
              ? 'b--red'
              : clearable && !!value
              ? 'b--moon-gray'
              : 'b--silver',
            readOnly ? 'bg-light-gray' : 'bg-white'
          )}
          type={type}
          style={{
            paddingTop: '0.75rem',
            paddingBottom: '0.75rem',
            paddingLeft: prefixIcon ? '50px' : '15px',
            backgroundImage: prefixIcon ? `url('${prefixIcon}')` : null,
            backgroundSize: '21px 22px',
            backgroundPosition: '15px center',
            backgroundRepeat: 'no-repeat',
            boxShadow: errorMessage ? `#c81349 0 0 3px` : null,
          }}
          autoComplete="off"
          onChange={onChange}
          value={value || ''}
          readOnly={readOnly}
          {...rest}
        />
        {clearable && (
          <button
            type="button"
            disabled={!value}
            className={cx(
              s['input-clear'],
              'bn bg-transparent ph3 pv2 absolute top-0 right-0 bottom-0 light-silver pointer flex items-center justify-center',
              {
                'o-0': !value,
                'o-100': value,
              }
            )}
            onClick={() => onChange({ target: { value: '' } })}
          >
            <CloseIcon alt="Slet input" width={16} height={16} />
          </button>
        )}
        {errorMessage && (
          <span className="absolute right-0 bottom-0 pv1 pr2 f7 red">
            {errorMessage}
          </span>
        )}
      </div>
    )
  }
}

Input.propTypes = {
  value: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  clearable: PropTypes.bool,
  prefixIcon: PropTypes.string,
}

export default Input
