import dayjs from 'dayjs'
import get from 'lodash/get'
import { mergeDeepRight } from 'ramda'
import axios from 'axios'
import normalize from 'json-api-normalizer'

import accountUsersEndpointConverter from './converters/accountUsersEndpoint'
import singleAccountEndpointConverter from './converters/singleAccountEndpoint'
import loggedInUserEndpointConverter from './converters/loggedInUserEndpoint'
import usageReportEndpointConverter from './converters/usageReportEndpoint'
import ventilationReportEndpointConverter from './converters/ventilationReportEndpoint'
import co2ReportEndpointConverter from './converters/co2ReportEndpoint'
import { realApi, realApiAsAngel, apiProxy } from './httpClient'
import accounts from '../../state/accounts'

const handleError = (error) => {
  if (axios.isCancel(error)) {
    // Request cancelled, ignore
  } else if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    throw new Error(
      `${error.response.status} – ${error.response.data.errors
        .map((e) =>
          [
            e.title,
            get(e, 'source.parameter'),
            get(e, 'source.pointer'),
            e.detail,
          ]
            .filter(Boolean)
            .join(' – ')
        )
        .filter(Boolean)
        .join(', ')}`
    )
  } else {
    // The request was made but no response was received or
    // something happened in setting up the request that triggered an Error
    throw new Error(error.message)
  }
}

export async function fetchAccountUsers({ email, password }) {
  try {
    // The login request should always go through the real API
    const { data } = await realApi.get(
      '/v6/account-users?include=account,user',
      {
        auth: {
          username: email,
          password: password,
        },
      }
    )
    return accountUsersEndpointConverter(data)
  } catch (error) {
    handleError(error)
  }
}

export async function fetchTenantAccount({ id }) {
  try {
    // This request will only be made by a logged-in angel, thus the real API
    const { data } = await realApiAsAngel.get(`/v6/accounts/${id}`)
    if (get(data, ['data', 'attributes', 'role']) !== 'tenant') {
      throw new Error(`ID ${id} er ikke en beboerkonto`)
    }
    return singleAccountEndpointConverter(data)
  } catch (error) {
    handleError(error)
  }
}

export async function fetchLoggedInUser() {
  try {
    const { data } = await apiProxy().get('/v6/ping?include=user')
    return loggedInUserEndpointConverter(data)
  } catch (error) {
    handleError(error)
  }
}

export async function fetchVentilationReport({ roomId, fromDate, toDate }) {
  try {
    const { data } = await apiProxy().post('/v6/reports/ventilation', {
      data: {
        type: 'ventilation-reports',
        relationships: {
          room: {
            data: {
              type: 'rooms',
              id: roomId,
            },
          },
        },
        attributes: {
          'to-date': toDate,
          'from-date': fromDate,
        },
      },
    })
    return ventilationReportEndpointConverter(data)
  } catch (error) {
    handleError(error)
  }
}

export async function fetchCo2Report({ roomId, fromDate, toDate }) {
  try {
    const { data } = await apiProxy().post('/v6/reports/co2', {
      data: {
        type: 'co2-reports',
        relationships: {
          room: {
            data: {
              type: 'rooms',
              id: roomId,
            },
          },
        },
        attributes: {
          'to-date': toDate,
          'from-date': fromDate,
        },
      },
    })
    return co2ReportEndpointConverter(data)
  } catch (error) {
    handleError(error)
  }
}

export async function fetchUsageReport({
  householdId,
  fromDate,
  toDate,
  dataType,
  dataAttributes,
}) {
  try {
    // dataType 'heat-cost-reports' => endpoint '/reports/heat-cost' etc.
    const endpoint = `/v6/reports/${dataType.replace('-reports', '')}`
    const { data } = await apiProxy().post(endpoint, {
      data: {
        type: dataType,
        relationships: {
          household: {
            data: {
              type: 'households',
              id: householdId,
            },
          },
        },
        attributes: {
          'from-date': dayjs(fromDate).format('YYYY-MM-DD'),
          'to-date': dayjs(toDate).format('YYYY-MM-DD'),
          'days-per-point': 1,
          ...dataAttributes,
        },
      },
    })
    return usageReportEndpointConverter(data)
  } catch (error) {
    handleError(error)
  }
}

function suppressError(message = '') {
  // The _avast_submit error is from a browser plugin
  if (message.indexOf('_avast_submit') >= 0) {
    return true
  } else {
    return false
  }
}

export function logEvent({ level = 'info', message }) {
  // Don't log events in development or test scenarios or for suppressed errors
  if (
    !/wisehome\.dk/.test(window.location.hostname) ||
    suppressError(message)
  ) {
    return false
  }
  try {
    const { activeApiKey, activeSudoAccount, accountList } = accounts.get()
    // level can be 'info', 'warn' or 'error'
    realApi.post('/v6/logs', {
      data: {
        type: 'logs',
        attributes: {
          level,
          message: [
            message,
            window.location.href,
            activeApiKey
              ? `account ${
                  (accountList.find((x) => x.apiKey === activeApiKey) || {}).id
                }`
              : 'demo mode',
            activeSudoAccount ? `sudo ${activeSudoAccount}` : '',
            `${window.innerWidth}x${window.innerHeight}`,
            window.navigator.userAgent,
          ]
            .filter(Boolean)
            .join(' :: '),
          application: 'pippin',
        },
      },
    })
  } catch (ignore) {
    // If logging an error fails, there's really nowhere to log it
  }
}

// Normalizing, non-converting endpoints ==================================== //

// Normalize a JSON API response and merge with an existing resources store
export const updateResources = (newResources) => (existingResources) => {
  return mergeDeepRight(existingResources, normalize(newResources))
}

// Accounts ----------------------------------------------------------------- //
export async function searchTenantAccounts({ searchTerm = '' }, updaterFunc) {
  try {
    // Only used by an Angel user, therefore search the real API as angel
    const { data } = await realApiAsAngel.get(
      `/v6/accounts?page[size]=10&filter[role]=tenant&filter[name-search]=${searchTerm}`
    )
    if (updaterFunc) {
      return updaterFunc(updateResources(data))
    } else {
      return data
    }
  } catch (error) {
    handleError(error)
  }
}

export async function getUserActivation({ token }, updaterFunc) {
  try {
    // Account activation should always go through the real API
    const { data } = await realApi.get(`/v6/user-activations/${token}`)
    if (updaterFunc) {
      return updaterFunc(updateResources(data))
    } else {
      return data
    }
  } catch (error) {
    handleError(error)
  }
}

export async function activateUser({ name, password, token }, updaterFunc) {
  try {
    // Account activation should always go through the real API
    const { data } = await realApi.post('/v6/user-activations', {
      data: {
        attributes: {
          name,
          password,
          token,
        },
        type: 'user-activations',
      },
    })
    if (updaterFunc) {
      return updaterFunc(updateResources(data))
    } else {
      return data
    }
  } catch (error) {
    handleError(error)
  }
}

// Tenancies ---------------------------------------------------------------- //
export async function loadTenancies(_, updaterFunc) {
  try {
    const { data } = await apiProxy().get(`/v6/tenancies`)
    if (updaterFunc) {
      return updaterFunc(updateResources(data))
    } else {
      return data
    }
  } catch (error) {
    handleError(error)
  }
}

// Households --------------------------------------------------------------- //
export async function loadHousehold({ householdId }, updaterFunc) {
  try {
    const { data } = await apiProxy().get(
      `/v6/households/${householdId}?include=address`
    )
    if (updaterFunc) {
      return updaterFunc(updateResources(data))
    } else {
      return data
    }
  } catch (error) {
    handleError(error)
  }
}

// Password reset ----------------------------------------------------------- //
export async function requestPasswordReset({ email }) {
  try {
    // The reset password request should always go through the real API
    await realApi.post('/v6/password-resets', {
      data: {
        attributes: {
          email,
        },
        type: 'password-resets',
      },
    })
  } catch (error) {
    handleError(error)
  }
}

export async function performPasswordReset({ token, password }) {
  try {
    // Resetting a password should always go through the real API
    await realApi.patch(`/v6/password-resets/${token}`, {
      data: {
        attributes: {
          password,
        },
        id: token,
        type: 'password-resets',
      },
    })
  } catch (error) {
    handleError(error)
  }
}

// Devices ------------------------------------------------------------------ //
export async function loadDevicesByHousehold({ householdId }, updaterFunc) {
  try {
    const { data } = await apiProxy().get(
      `/v6/devices?filter[household-id]=${householdId}&include=room,device-type,last-measurements`
    )
    if (updaterFunc) {
      return updaterFunc(updateResources(data))
    } else {
      return data
    }
  } catch (error) {
    handleError(error)
  }
}
