import React from 'react'
import cx from 'classnames'
import { formatHouseholdNumber } from '../helpers/numbers'

export function PageHeading({ children }) {
  return <h1 className="f3 f2-m f1-l fw3 mt1 mt2-ns mb3 mb4-l">{children}</h1>
}

export function SubHeading({ className, children }) {
  return <h2 className={cx('fw9 f4 navy', className)}>{children}</h2>
}

export function FallbackText({ children }) {
  return (
    <span className="dib ph2 pv1 br1 f8 ba b--light-silver bg-light-gray moon-gray ttu tracked">
      {children}
    </span>
  )
}

export function NumberWithIdFallback({
  number,
  id,
  virtual = false,
  className,
}) {
  const formattedHouseholdNumber = formatHouseholdNumber(number, virtual)
  return (
    <span
      className={cx('help', className)}
      title={`Nummer: ${formattedHouseholdNumber} (ID: ${id})`}
    >
      {formattedHouseholdNumber || id}
    </span>
  )
}
