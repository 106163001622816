import { wrapResponse } from './utils'

export default function generatePing() {
  return wrapResponse({
    data: {
      attributes: {
        message: 'Pong from Wise Home API',
      },
      id: '',
      relationships: {
        account: {
          data: {
            id: '2',
            type: 'accounts',
          },
        },
        user: {
          data: {
            id: '1',
            type: 'users',
          },
        },
      },
      type: 'pongs',
    },
    included: [
      {
        attributes: {
          'activated-at': '2018-11-29T12:00:00.000000Z',
          'activation-token': null,
          email: 'demo@wisehome.dk',
          name: 'Demo Bruger',
        },
        id: '1',
        type: 'users',
      },
    ],
  })
}
