export function getVersionString() {
  const {
    REACT_APP_BUILD_NUM: BUILD_NUM = '',
    REACT_APP_BUILD_SHA: BUILD_SHA = '',
    NODE_ENV,
  } = process.env

  return NODE_ENV === 'production' && BUILD_NUM && BUILD_SHA
    ? `v${BUILD_NUM}.${BUILD_SHA.substring(0, 6)}`
    : NODE_ENV
}
