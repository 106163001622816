import * as R from 'ramda'
import { formatUnit } from './units'

export function formatNumber(
  number,
  maximumFractionDigits,
  minimumFractionDigits
) {
  if (Number.isFinite(number)) {
    return number.toLocaleString('da-DK', {
      maximumFractionDigits,
      minimumFractionDigits,
    })
  } else {
    return '—'
  }
}

export const parseFormattedNumber = R.pipe(
  R.split(/,00$/),
  R.head,
  R.defaultTo('0'),
  R.replace(/\./g, ''),
  Number,
  R.when(Number.isNaN, R.always(0))
)

export function formatHouseholdNumber(number, virtual = false) {
  if (!number) {
    return ''
  } else if (virtual) {
    return `S${number}`
  } else {
    return number.toString()
  }
}

export function formatQuantity(quantity = '') {
  const [value, unit] = R.pipe(R.defaultTo(''), R.split(' '))(quantity)
  const fractionDigitCount = R.pipe(
    R.split('.'),
    R.nth(1),
    R.defaultTo(''),
    R.length
  )(value)

  const formattedValue = formatNumber(
    value ? Number(value) : undefined,
    fractionDigitCount,
    fractionDigitCount
  )
  const formattedUnit = formatUnit((unit ?? '').trim())

  return R.trim(formattedValue + formattedUnit)
}
