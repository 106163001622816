import React from 'react'
import cx from 'classnames'

const FormLabel = ({
  title,
  className,
  readOnly = false,
  isSynchronized = false,
  children,
  ...rest
}) => (
  <label className={cx(className, 'db pointer')} {...rest}>
    <span className="db f7 mb1 moon-gray">
      {title +
        (readOnly
          ? isSynchronized
            ? ' – synkroniseret fra Unik'
            : ' — kan p.t. ikke redigeres'
          : '')}
    </span>
    {children}
  </label>
)

export default FormLabel
