export default function loggedInUserEndpointConverter({ included }) {
  // There is only one user included
  const user = included
    .filter((x) => x.type === 'users')
    .map((u) => ({
      id: u.id,
      name: u.attributes.name,
      email: u.attributes.email,
    }))[0]

  return user
}
