import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const Box = ({ className, style, icon, title, children, ...rest }) => (
  <div
    className={cx(
      'bg-white ba b--light-silver br2 pa3 pa4-l shadow-4',
      className
    )}
    style={style}
    {...rest}
  >
    {title && (
      <div className="flex justify-center items-center h1">
        {icon && (
          <img src={icon} height={30} width={30} alt="" className="mr3" />
        )}
        <h2 className="f4 fw9 mv0 navy">{title}</h2>
      </div>
    )}
    {children}
  </div>
)

Box.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  icon: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
}

export default Box
