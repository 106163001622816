import { state, update } from 'bey-fix'
import { datadogLogs } from '@datadog/browser-logs'

const accounts = state({
  showLoginModal: true,
  activeApiKey: null,
  activeSudoAccount: null,
  cachedEmail: null,
  accountList: [],
})

const persistAccountList = () => {
  try {
    localStorage.setItem(
      'accountList',
      JSON.stringify(accounts.get().accountList)
    )
  } catch (error) {
    datadogLogs.logger.warn(`accounts.persistAccountList: ${error.message}`)
  }
}

const persistActiveApiKey = () => {
  try {
    localStorage.setItem('activeApiKey', accounts.get().activeApiKey)
  } catch (error) {
    datadogLogs.logger.warn(`accounts.persistActiveApiKey: ${error.message}`)
  }
}

export const sortAccounts = (accountsArray) => [
  ...accountsArray.filter((o) => o.role === 'angel'),
  ...accountsArray
    .filter((o) => o.role !== 'angel')
    .sort((a, b) => a.name.localeCompare(b.name, 'da')),
]

export const setAccountList = (newAccountList = []) => {
  update(accounts, (state) => {
    state.accountList = sortAccounts(newAccountList)
  })
  persistAccountList()
}

export const addToAccountList = (newAccount) => {
  if (newAccount) {
    update(accounts, (state) => {
      // Only add the account if it isn't already on the list
      if (state.accountList.findIndex((x) => x.id === newAccount.id) < 0) {
        state.accountList = sortAccounts([...state.accountList, newAccount])
      }
    })
    persistAccountList()
  }
}

export const setActiveApiKey = (apiKey = null) => {
  update(accounts, (state) => {
    state.activeApiKey = apiKey
  })
  persistActiveApiKey()
}

export const setActiveSudoAccount = (accountId = null) =>
  update(accounts, (state) => {
    state.activeSudoAccount = accountId
  })

export const clearActiveSudoAccount = () =>
  update(accounts, (state) => {
    state.activeSudoAccount = null
  })

export const setCachedEmail = (email) =>
  update(accounts, (state) => {
    state.cachedEmail = email
  })

export const setShowLoginModal = (bool) => {
  update(accounts, (state) => {
    state.showLoginModal = bool
  })
}

export default accounts
