import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { ReactComponent as CloseIcon } from '../graphics/close.svg'

const Alert = ({
  type = 'error',
  className,
  fontSizeClassName = 'f6',
  isDismissable = false,
  onDismiss,
  children,
}) => (
  <div
    className={cx(
      className,
      fontSizeClassName,
      'pv2 ph3 br1 mb3 lh-copy flex flex-row items-start',
      {
        'red bg-washed-red': type === 'error',
        'orange bg-washed-yellow ba b--yellow': type === 'warning',
        'navy bg-washed-blue': type === 'info',
      }
    )}
  >
    <span>{children}</span>
    {isDismissable && (
      <button
        type="button"
        className={
          'bn bg-transparent pl1 pv1 pr0 moon-gray hover-navy pointer ml-auto flex items-center justify-center'
        }
        onClick={onDismiss}
        title="Luk beskeden"
      >
        <CloseIcon alt="Kryds ikon" width={14} height={14} />
      </button>
    )}
  </div>
)

Alert.propTypes = {
  type: PropTypes.oneOf(['error', 'warning', 'info']),
  className: PropTypes.string,
  isDismissable: PropTypes.bool,
  onDismiss: PropTypes.func,
  children: PropTypes.node,
}

export default Alert
