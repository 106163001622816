import { state, update } from 'bey-fix'

const user = state({
  id: null,
  name: null,
  email: null,
})

export const setUser = ({ id, name, email }) =>
  update(user, (state) => ({ id, name, email }))

export default user
