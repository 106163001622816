import React from 'react'
import cx from 'classnames'
import { ReactComponent as SpinnerIcon } from '../graphics/favicon-monochrome.svg'
import s from './style.module.css'

const Spinner = ({ className, style }) => (
  <div className={cx(className, 'tc')} style={style}>
    <SpinnerIcon
      className="light-silver dib"
      width={50}
      height={50}
      style={{
        opacity: 0,
        animation: `${s['fade']} 750ms ease-in-out 250ms infinite alternate`,
      }}
    />
  </div>
)

export default Spinner
