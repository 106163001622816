import React from 'react'
import * as R from 'ramda'
import { Link } from '@reach/router'
import { Subscribe } from 'bey-fix'

import Box from '../Box'

import accounts from '../state/accounts'
import personIcon from '../graphics/person.svg'

function AccountList({ accountList = [], activeAccountId, navigate }) {
  if (activeAccountId) {
    navigate(`/account/${activeAccountId}`, {
      replace: true,
    })
  } else {
    // In demo mode, redirect to account 'demo'
    const accountId = R.pipe(
      R.head,
      R.prop('id'),
      R.defaultTo('demo')
    )(accountList)
    navigate(`/account/${accountId}`, {
      replace: true,
    })
  }

  return (
    <Box title="Vælg en konto" icon={personIcon} className="mw6 center">
      <ul className="list pl0 lh-copy tc f6">
        {accountList.map(({ id, name }) => (
          <li key={id}>
            <Link to={`/account/${id}`} className="link orange underline-hover">
              {name}
            </Link>
          </li>
        ))}
      </ul>
    </Box>
  )
}

export default function SubscribedAccountList(props) {
  return (
    <Subscribe to={accounts}>
      {({ accountList, activeApiKey }) => (
        <AccountList
          accountList={R.pipe(
            R.filter(R.propEq('role', 'tenant')),
            R.map(R.pick(['id', 'name']))
          )(accountList)}
          activeAccountId={R.pipe(
            R.find(
              R.where({
                role: R.equals('tenant'),
                apiKey: R.equals(activeApiKey),
              })
            ),
            R.prop('id')
          )(accountList)}
          {...props}
        />
      )}
    </Subscribe>
  )
}
