import dayjs from 'dayjs'
import random from 'lodash/random'
import get from 'lodash/get'
import sumBy from 'lodash/sumBy'

import { formatDateAsISOWithoutOffset, eachDay } from '../../dates'
import { wrapResponse } from './utils'

const lookup = {
  'water-usage-reports': {
    name: 'water usage',
    unit: 'm³',
    minDailyValue: 0.125,
    maxDailyValue: 0.45,
  },
  'heat-cost-reports': {
    name: 'heat cost',
    unit: 'units',
    minDailyValue: 0.5,
    maxDailyValue: 3.5,
  },
  'heat-usage-reports': {
    name: 'heat usage',
    unit: 'MWh',
    minDailyValue: 0.0005,
    maxDailyValue: 0.0035,
  },
  'electricity-usage-reports': {
    name: 'electricity usage',
    unit: 'MWh',
    minDailyValue: 0.0075,
    maxDailyValue: 0.0105,
  },
}

export default function generateUsageReport({
  fromDate,
  toDate,
  type,
  waterType,
}) {
  const settings = get(lookup, type)
  const points = eachDay(fromDate, toDate).map((d) => ({
    time: formatDateAsISOWithoutOffset(d),
    // Return 0 for dates in the future
    value: dayjs(d).isAfter(dayjs(), 'day')
      ? 0
      : random(get(settings, 'minDailyValue'), get(settings, 'maxDailyValue')),
  }))
  const totalUsageValue = sumBy(points, 'value')

  return wrapResponse({
    data: {
      id: '',
      type,
      attributes: {
        'days-per-point': 1,
        'from-date': dayjs(fromDate).format('YYYY-MM-DD'),
        'to-date': dayjs(toDate).format('YYYY-MM-DD'),
        waterType,
        'total-usage': {
          time: formatDateAsISOWithoutOffset(fromDate),
          unit: get(settings, 'unit'),
          value: totalUsageValue,
        },
        usage: {
          name: get(settings, 'name'),
          unit: get(settings, 'unit'),
          points,
        },
      },
    },
  })
}
